function isInteger(str) {
	var n = ~~Number(str);
	return String(n) === str && n >= 1;
}

function validForm( el ) {
	var error = 0, txtError,
		theForm = el.closest('form'),
		elParent = el.closest('.formRow'),
		elTag = el.prop("tagName"),
		theButton = theForm.find('.buttons').find('input[type="submit"]');

	// Passer tout les inpute de type text
	if( el.hasClass('validFileOnly') ) {
		var elVal = el.val();

		txtError = 0;

		if( elVal.length > 1 ) {
			if( elVal.substr( -3 ) !== 'pdf' ) {
				elParent.removeClass('correct').addClass('bad');
				txtError = 1;
			} else if( el[0].files && el[0].files[0] ) {
				var fileSize = Math.round( parseInt( el[0].files[0].size ) / 1024 );

				if( elVal.substr( -3 ) !== 'pdf' || fileSize > 4096 ) {
					elParent.removeClass('correct').addClass('bad');
					txtError = 1;
				}
			}
		}

		error = txtError;

		if( !txtError ) {
			elParent.removeClass('bad').addClass('correct');
		}
	} else if( elTag === 'INPUT' || elTag === 'TEXTAREA' ) {
		var elVal = el.val(),
			validEmail = /[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
			validTelephone = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;

		txtError = 0;

		// S'assurer qu'il y a au moins 2 caractères
		if( ( el.attr('type') === 'text' || el.attr('type') === 'password' || el.attr('type') === 'tel' || elTag === 'TEXTAREA' ) && elVal.length < 2 ) {
			elParent.removeClass('correct').addClass('bad');
			txtError = 1;
		}

		// Si c'est un type email, je m'assure qu'il est sous le bon format
		if( el.attr('type') === 'email' && !validEmail.test( elVal ) ) {
			elParent.removeClass('correct').addClass('bad');
			txtError = 1;
		}

		// Si c'est un type telephone, je m'assure qu'il est sous le bon format
		/*if( el.attr('type') === 'tel' && !validTelephone.test( elVal ) ) {
			elParent.removeClass('correct').addClass('bad');
			txtError = 1;
		}*/

		// Si c'est un type tel, je m'assure qu'il a au moins 10 caractères
		if( el.attr('type') === 'number' && elVal.length > 2 && isInteger( elVal )) {
			elParent.removeClass('correct').addClass('bad');
			txtError = 1;
		}

		// Si c'est un type file, je m'assure que c'est un PDF
		if( el.attr('type') === 'file' ) {
			if( elVal.length > 1 && elVal.substr( -3 ) !== 'pdf' ) {
				elParent.removeClass('correct').addClass('bad');
				txtError = 1;
			} else if( el[0].files && el[0].files[0] ) {
				var fileSize = Math.round( parseInt( el[0].files[0].size ) / 1024 );

				if( fileSize > 4096 ) {
					elParent.removeClass('correct').addClass('bad');
					txtError = 1;
				}
			}
		}

		error = txtError;

		if( !txtError ) {
			elParent.removeClass('bad').addClass('correct');
		}
	} else if( elTag === 'SELECT' && el.hasClass('required') ) {
		txtError = 0;

		// S'assurer qu'il y a au moins 2 caractères
		if( !el.val() ) {
			el.removeClass('correctSelect').addClass('badSelect');
			elParent.removeClass('correct').addClass('bad');
			txtError = 1;
		}

		error = txtError;

		if( !txtError ) {
			el.removeClass('badSelect').addClass('correctSelect');
			elParent.removeClass('bad').addClass('correct');
		}
	}

	var allOK = 1;
	theForm.find('.formRow').find('.required').closest('.formRow').each(function(){
		if( !$(this).hasClass('correct') ) {
			allOK = 0;
		}
	});

	theForm.find('.formRow').find('.validFileOnly').closest('.formRow').each(function(){
		if( $(this).hasClass('bad') ) {
			allOK = 0;
		}
	});

	return allOK;

	/*if( allOK ) {
		theButton.prop('disabled', false);
	} else{
		theButton.prop('disabled', true);
	}*/

}