var vWidth = $(window).width();

//var isCloseSwal = null;

function copyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    var theReturn = null;

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a flash,
    // so some of these are just precautions. However in IE the element
    // is visible whilst the popup box asking the user for permission for
    // the web page to copy to the clipboard.
    //

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.bottom = 0;
    textArea.style.right = 0;
    textArea.style.color = '#FFFFFF';

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';


    textArea.value = text;

    document.body.appendChild(textArea);

    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        //console.log('Copying text command was ' + msg);
        theReturn = true;
    } catch (err) {
        //console.log('Oops, unable to copy');
        theReturn = false;
    }

    document.body.removeChild(textArea);

    return theReturn;
}

function toggleSidebar(sidebar, action) {
    //action est optionnel ; ça lancera le default si non défini
    action = action || 'undefined';

    if (sidebar === undefined || sidebar === '' || !sidebar) {
        console.error('Sidebar invalide. Veuillez fournir un objet jQuery ou un sélecteur CSS valide.');
        return false;
    } else if (typeof sidebar == 'string') {
        sidebar = $(sidebar);
    }

    switch (action) {
        case 'open':
            sidebar.addClass('open');
            break;

        case 'close':
            sidebar.removeClass('open');
            break;

        //case 'toggle':
        default:
            if (sidebar.hasClass('open')) {
                sidebar.removeClass('open');
            } else {
                sidebar.addClass('open');
            }
            break;
    }
}

function openAccordion(accordion) {
    var button = accordion.children('header').children('.btnRollUnroll');
    var content = accordion.children('.accordionContent');
    var closeLabel = button.attr('data-close-label');

    accordion.addClass('open');
    button.attr('aria-label', closeLabel);
    button.attr('title', closeLabel);
    content.slideDown(200);
}

function closeAccordion(accordion) {
    var button = accordion.children('header').children('.btnRollUnroll');
    var content = accordion.children('.accordionContent');
    var openLabel = button.attr('data-open-label');

    accordion.removeClass('open');
    button.attr('aria-label', openLabel);
    button.attr('title', openLabel);
    content.slideUp(200);
}

function doProgressiveMenu() {
    var headerWidth = $('#header .inner .wrap').width();
    var logoWidth = $('#logo').width();
    var availableSpace;
    var neededSpace = 0;
    var optionWidths = [];
    var optionsToHide = 0;

    if (vWidth >= 735) {
        //Largeur bureau. Le logo "vole" de la place au menu.
        availableSpace = headerWidth - logoWidth - 20;
    } else {
        //Largeur pas-bureau. Le logo est sur sa propre ligne.
        availableSpace = headerWidth;
    }

    //console.log('_____');
    $('#mainmenu > ul > li').each(function () {
        //console.log(neededSpace + ' + ' + ($(this).outerWidth(true) + 1) + ' = ');
        neededSpace += $(this).outerWidth(true) + 2;
        //console.log(neededSpace);
        optionWidths.push($(this).outerWidth(true) + 2);
    });

    //On ajoute au neededSpace le padding qu'il y a sur l'ensemble des menus. (En espérant qu'il soit défini en px)
    var menusPadding = $('#menus').css('padding-left');
    menusPadding = menusPadding.replace('px', '');
    menusPadding = parseInt(menusPadding);

    neededSpace += menusPadding;
    var newNeededSpace = neededSpace;

    //console.log(neededSpace + ' >? ' + availableSpace);
    //Le menu total est-il trop grand pour la largeur disponible ?
    if (neededSpace >= availableSpace) {
        //Si le menu total est trop grand, alors on sait qu'on va afficher le bouton du menu mobile. Dans ce cas, on ajouter sa largeur à celle dont on a besoin.
        newNeededSpace += $('.moreBtn').outerWidth(true);

        //On retire à l'espace nécessaire total la largeur de la dernière option de menu (.length - 1). Puis la boucle vérifie si c'est encore trop grand. Si oui, on retire l'avant dernière option, et ainsi de suite, jusqu'à ce que le menu rentre.
        while (newNeededSpace >= availableSpace) {
            // console.log('Trop grand!');

            newNeededSpace -= optionWidths[optionWidths.length - 1 - optionsToHide];
            optionsToHide += 1;
        }
    }
    //console.log(optionsToHide);

    //Aucune option de menu à cacher ? On cache tout le menu mobile. Sinon on l'affiche.
    if (optionsToHide <= 0) {
        if (!$('#moreContainer').hasClass('hide')) {
            $('#moreContainer').addClass('hide');
        }
    } else {
        if ($('#moreContainer').hasClass('hide')) {
            $('#moreContainer').removeClass('hide');
        }
    }

    //On doit cacher toutes les options du menu ? Alors cachons le menu bureau entier. Sinon on l'affiche.
    if (optionsToHide >= optionWidths.length) {
        if (!$('#mainmenu').hasClass('hide')) {
            $('#mainmenu').addClass('hide');
        }
    } else {
        if ($('#mainmenu').hasClass('hide')) {
            $('#mainmenu').removeClass('hide');
        }
    }

    //Affichons toutes les options du menu principal et cachons toutes les options du menu mobile pour partir sur de bonnes bases.
    $('#mainmenu > ul > li').removeClass('hide');
    $('#moreContainer .moreSubmenu > ul > li').addClass('hide');

    //Maintenant cachons du menu principal les X dernières options pour chaque option qu'on a calculé plus haut devoir cacher, et affichons en X à partir de la fin dans le menu mobile.
    for (var curOpt = 0; curOpt < optionsToHide; curOpt += 1) {
        $('#mainmenu > ul > li:not(.hide):last').addClass('hide');
        $('#moreContainer .moreSubmenu > ul > li.hide:last').removeClass('hide');
    }
}

function openMenu(menu) {
    // console.log('openMenu');
    menu.addClass('visible');

    setTimeout(function () {
        menu.addClass('fadeIn');
    }, 0);
}

function closeMenu(menu) {
    // console.log('closeMenu');
    menu.removeClass('fadeIn');

    setTimeout(function () {
        menu.removeClass('visible');
    }, 200);
}

$('#mainmenu li.parent').each(function () {
    $(this).mouseenter(function () {
        $(this).children('ul').fadeIn(100);
    });

    $(this).mouseleave(function () {
        $(this).children('ul').fadeOut(100);
    });
});

function toggleMobileMenu(action) {
    //Valeur par défaut du paramètre facultatif
    action = action || 'toggle';

    var menu = $('.moreSubmenu > ul');

    switch (action) {
        case 'open':
            if (!menu.hasClass('fadeIn')) {
                menu.parents('#moreContainer').addClass('menuOpen');
                openMenu(menu);
            }
            break;

        case 'close':
            if (menu.hasClass('fadeIn')) {
                menu.parents('#moreContainer').removeClass('menuOpen');
                closeMenu(menu);
            }
            break;

        //toggle
        default:
            if (menu.hasClass('fadeIn')) {
                menu.parents('#moreContainer').removeClass('menuOpen');
                closeMenu(menu);
            } else {
                menu.parents('#moreContainer').addClass('menuOpen');
                openMenu(menu);
            }
            break;
    }
}

function toggleUserInfos(action) {
    //Valeur par défaut du paramètre facultatif
    action = action || 'toggle';
    //console.log('toggle');

    var menu = $('#header .userinfos .infos');

    switch (action) {
        case 'open':
            if (!menu.hasClass('fadeIn')) {
                openMenu(menu);
            }
            break;

        case 'close':
            if (menu.hasClass('fadeIn')) {
                closeMenu(menu);
            }
            break;

        //toggle
        default:
            if (menu.hasClass('fadeIn')) {
                closeMenu(menu);
            } else {
                openMenu(menu);
            }
            break;
    }
}

/*
function statusChangeCallback(response) {
	console.log('statusChangeCallback');
	console.log(response);
	// The response object is returned with a status field that lets the
	// app know the current login status of the person.
	// Full docs on the response object can be found in the documentation
	// for FB.getLoginStatus().
	if (response.status === 'connected') {
		// Logged into your app and Facebook.
		testAPI();
	} else if (response.status === 'not_authorized') {
		// The person is logged into Facebook, but not your app.
		document.getElementById('status').innerHTML = 'Please log ' +
		'into this app.';
	} else {
		// The person is not logged into Facebook, so we're not sure if
		// they are logged into this app or not.
		document.getElementById('status').innerHTML = 'Please log ' +
		'into Facebook.';
	}
}
*/
function doCharCounter(elInput) {
    var elCounter = elInput.siblings('.wrapCharCounter');
    var elCurChars = elCounter.find('.charCounter').find('.curChars');
    var elMaxChars = elCurChars.siblings('.maxChars');
    var maxChars = parseInt(elMaxChars.text());

    var inputContent = elInput.val();
    var nbChars = inputContent.length;

    elCurChars.text(nbChars);

    if (nbChars > maxChars) {
        if (!elCounter.hasClass('tooLong')) {
            elCounter.addClass('tooLong');
        }
    } else {
        if (elCounter.hasClass('tooLong')) {
            elCounter.removeClass('tooLong');
        }
    }
}

// Valider le formulaire
$('.validForm').each(function () {
    var error = 0;
    var theForm = $(this);

    $(this).on("change keyup", 'input.required, textarea.required, select.required', function () {
        validForm($(this));
    });

    $(this).on('click', 'input[type="submit"]', function (e) {
        var valid = true;

        $(this).closest('form').find('.formRow').find('input.required, textarea.required, select.required').each(function () {
            if (validForm($(this))) {

                valid = false;
                return false;
            }
        });

        if (validForm(theForm)) {
            console.log('disabled submit valid form');
            $('.oneSubmit').attr('disabled', 'disabled');
            theForm.submit();
        } else {
            theForm.find('.bad').eq(0).find('input, textarea, select').focus();
            e.preventDefault();
        }
    });
});

// Smooth scroll for anchors
$('a[href*="#"]:not([href="#"])').not('.skip-link').click(function () {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top
            }, 1000);
            return false;
        }
    }
});

// Force du mot de passe
$('.formRow #password.showStrength').strength({
    strengthClass: 'strength',
    strengthMeterClass: 'strength_meter',
    strengthButtonClass: 'button_strength',
    strengthButtonText: $.i18n.prop('strength_show'),
    strengthButtonTextToggle: $.i18n.prop('strength_hide')
});

function loadCities() {
    var regionIds = $("#triRegion").val();
    var citieSelect = $('#triCity');

    citieSelect.html('');

    if (regionIds != undefined && regionIds.length > 0) {
        $.ajax({
            method: "GET",
            dataType: "json",
            url: "/ajax/getCitiesByRegionIds/" + regionIds
        }).success(function (jsonResult) {

            $.each(jsonResult, function (key, city) {
                citieSelect.append('<option value="' + city.name + '">' + city.name + '</option>');
            });
        })
    }
}

function select2JobSearchInitialisation(object) {
    object.select2({
        tags: true,
        selectOnClose: true,
        allowClear: true,
        minimumInputLength: 1,
        language: {
            inputTooShort: function () {
                return '';
            }
        }
    });
}

function displaySearchTag(object){
    if(object.val() == null){
        object.closest('.formRow').find('.select2-container--custom-selection').css('display', 'none')
    }
    else{
        object.closest('.formRow').find('.select2-container--custom-selection').css('display', '')
    }
}

function diableJobAlertSubmitButton(){
    if ($('#triSector').val() != null || $('#triSchedule').val() != null || $('#triCity').val() != null || $('#triRegion').val() != null || $('#triJobTile').val() != null) {
        $('#createJobAlert').prop('disabled', false);
    } else {
        $('#createJobAlert').prop('disabled', true);
    }
}

function disableCity(){
    var regionId =  $( "#region" ).val();

    if(regionId == "" || regionId == 75){
        $( "#city" ).css('opacity', '0.5');
        $( "#city" ).prop( "disabled", true );
        $('#city').css("background-color", "rgb(235,235,228)");
    }else{
        $( "#city" ).css('opacity', '');
        $( "#city" ).prop( "disabled", false );
        $('#city').css("background-color", "rgb(255,255,255)");
    }
}

// We want to manually init the dropzone.
$(function () {

    var CustomSelectionAdapter = $.fn.select2.amd.require("select2/selection/customSelectionAdapter");

    $('select.filtre_select').select2({
        allowClear: true,
    });

    $('select.filtre_select_searchFilter').select2({
        selectionAdapter: CustomSelectionAdapter,
    });

    $('select.filtre_select_jobAlert').select2({
        disabled: true,
    });

    $('select.filtre_select_searchFilter_searchJobTitle').select2({
        tags: true,
        selectOnClose: true,
        minimumInputLength: 1,
        selectionAdapter: CustomSelectionAdapter,
        language: {
            inputTooShort: function () {
                return '';
            }
        }
    });

    disableCity();
    diableJobAlertSubmitButton();
    select2JobSearchInitialisation($('select.filtre_select_searchJobTitle'));

    $('#triRegion').on('change', function () {
        loadCities();
    });

    displaySearchTag($('.filtre_select_searchFilter_searchJobTitle'));

    $('.filtre_select_searchFilter').each(function(){
        displaySearchTag($(this));
    })

    $('.filtre_select_searchFilter, .filtre_select_searchFilter_searchJobTitle').on('change', function () {
        displaySearchTag($(this));
    });

    if ($('#triSector').val() != null || $('#triSchedule').val() != null) {
        $('#advancedSearchDiv').toggle(true);
    }

    $('#region').change(function () {
        var region = $('#region option:selected').val();
        var citieSelect = $('#city');

        citieSelect.empty();

        if (region != undefined && region.length > 0) {
            if(region == 75 && $('#city').hasClass("required")){
                $('#city').removeClass('required');
            }
            else
            {
                var optionCity = JSON.parse($('#region option:selected')[0].dataset.city);

                $.each(optionCity, function (key, value) {
                    citieSelect.append("<option value='" + value['name'] + "'>" + value['name'] + "</option>");
                });

                if(!$('#city').hasClass("required")){
                    $('#city').addClass('required');
                }
            }
        }
    });

    $( "#region" ).change(function() {
        disableCity();
    });

    /***** Setup-er les ajax pour qu'ils envoient le CSRF *****/
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    //Menu progressif v3
    doProgressiveMenu();

    $(window).resize(function () {
        vWidth = $(window).width();
        doProgressiveMenu();
    });

    //Gestion du menu mobile
    $('.moreBtn').click(function () {
        toggleMobileMenu();
    });

    //Gestion du menu compte "Bonjour <Nom utilisateur>"
    $('#header .userinfos .trigger').mouseenter(function () {
        // console.log('enter');
        toggleUserInfos('open');
    });

    $('#header .userinfos').mouseleave(function () {
        // console.log('leave');
        toggleUserInfos('close');
    });

    $('#header .userinfos .trigger').click(function (event) {
        //Au clic, si la bulle-menu n'est pas ouverte, on l'ouvre au lieu de suivre le lien
        if (!$(this).siblings('.infos').hasClass('fadeIn')) {
            event.preventDefault();
            toggleUserInfos('open');
        }
    });

    $('body').click(function (event) {
        //Cliquer n'importe où sauf dans lui-même ferme le menu mobile
        toggleMobileMenu('close');

        //Cliquer n'importe où ferme tous les sous-menus (sauf celui dans lequel on a cliqué, si tel est le cas)
        $('.link.parent').removeClass('clicked');
        if ($(event.target).parents('.link.parent').length > 0) {
            $(event.target).parents('.link.parent').addClass('clicked');
        }
    });

    //Cliquer sur le menu mobile lui-même ne le ferme pas
    $('.moreSubmenu > ul').click(function (event) {
        event.stopPropagation();
    });

    // Formulaire demande de recommandation - Afficher masquer le champs "Votre entreprise" au besoin
    $('.testimonials input[name="jobType"]').change(function () {
        if ($(this).val() == 'company') {
            $('#ciename').closest('.formRow').show(150);
        } else {
            $('#ciename').closest('.formRow').hide(150);
        }
    })

    /***** Scripts sur le formulaire d'inscription *****/
    if ($('body').hasClass('containForm')) {
        $('.withOtherField input[type="checkbox"], .withOtherField input[type="radio"]').change(function () {
            if ($(this).is(':checked')) {
                $(this).closest('.withOtherField').find('input[type="text"]').prop('disabled', false).focus();
            } else {
                $(this).closest('.withOtherField').find('input[type="text"]').prop('disabled', true).val('');
            }
        });

        // Ouvrir les checkbox enfants lorsque nécessaire
        if ($('input[type="checkbox"].parent').first()) {
            $('input[type="checkbox"].parent').change(function () {
                var childsContainer = $(this).closest('.formRow').find('.formRowChild');

                if ($(this).is(':checked')) {
                    childsContainer.show(150, function () {
                        $(this).toggleClass('hide');
                    });
                } else {
                    childsContainer.hide(150);
                    childsContainer.find('input[type="checkbox"]').attr('checked', false);
                }

            });
        }

        if ($('select.sectorParent').first() && $('select.sectorChild').first()) {
            $(document.body).on('change', 'select.sectorParent', function () {
                var tofeed = $(this).attr('data-tofeed');

                $.ajax({
                    method: "GET",
                    'dataType': "json",
                    url: "/ajax/getjobs/" + $(this).val()
                }).done(function (json) {
                    $('#' + tofeed + ' option:not(:first)').remove();

                    $.each(json.results, function (key, value) {
                        $('#' + tofeed).append('<option value="' + value.name + '">' + value.name + '</option>');
                    });

                    $('#' + tofeed).attr('disabled', false);
                });
            });
        }

        var cpt;
        $('a.addXP').click(function (e) {
            e.preventDefault();

            $('select.filtre_select_searchJobTitle').select2('destroy');

            if (cpt === undefined) {
                cpt = parseInt($(this).attr('data-index'));
            }

            var link = $(this).attr('data-link'),
                clonedXP,
                xppro = false;

            cpt += 1;

            clonedXP = $('.' + link).eq(0).clone();
            clonedXP.find('input, select, textarea').each(function () {
                this.name = this.name.replace('[0]', '[' + cpt + ']');
                this.id = this.id.replace('-1', '-' + (cpt + 1));
                if ($(this).attr('data-tofeed')) {
                    $(this).attr('data-tofeed', $(this).attr('data-tofeed').replace('-1', '-' + (cpt + 1)));
                    xppro = $(this).attr('data-tofeed');
                }
                $(this).prev('label').attr('for', this.id);
                $(this).closest('.correct').removeClass('correct');
                $(this).closest('.correctSelect').removeClass('correctSelect');
                $(this).val('');
            });
            clonedXP.find('.no').html(cpt + 1);
            if (xppro) {
                clonedXP.find('#' + xppro).prop('disabled', true).children('option').eq(0).nextAll().remove();
            }

            clonedXP.append($('.modelRemoveXp').html());
            clonedXP.appendTo('#experiences').hide().show(350);

            select2JobSearchInitialisation($('select.filtre_select_searchJobTitle'));
        });

        $(document).on('click', '.experience .remove a', function (e) {
            e.preventDefault();
            $(this).closest('.experience').hide(350, function () {
                $(this).remove();
                cpt -= 1;
                $('#experiences .experience .no').each(function (i) {
                    $(this).html(i + 1);
                });
            });
        });

        $('#phone')

            .keydown(function (e) {
                var key = e.which || e.charCode || e.keyCode || 0;
                $phone = $(this);

                //only put the first parens when the user starts typing
                if ($phone.val().length !== 0 && $phone.val().charAt(0) !== '(') {
                    let val = $phone.val();
                    $phone.val("(" + val);
                }

                if (($phone.val().length >= 15 && $phone.val().length <= 19) && (key === 8 || key === 46)) {
                    $phone.val($phone.val().substring(0, 14));
                    return false;
                }

                // Auto-format- do not expose the mask as the user begins to type
                if (key !== 8 && key !== 9) {
                    if ($phone.val().length === 4) {
                        $phone.val($phone.val() + ')');
                    }
                    if ($phone.val().length === 5) {
                        $phone.val($phone.val() + ' ');
                    }
                    if ($phone.val().length === 9) {
                        $phone.val($phone.val() + '-');
                    }
                    if ($phone.val().length === 14) {
                        $phone.val($phone.val() + ' ext:');
                    }
                }

                // Allow numeric (and tab, backspace, delete) keys only
                return (key == 8 ||
                    key == 9 ||
                    key == 46 ||
                    (key >= 48 && key <= 57) ||
                    (key >= 96 && key <= 105));
            })

            .bind('focus click', function () {
                $phone = $(this);
                if ($phone.val().length !== 0) {
                    var val = $phone.val();
                    $phone.val('').val(val); // Ensure cursor remains at the end
                }
            })

            .blur(function () {
                $phone = $(this);

                if ($phone.val() === '(') {
                    $phone.val('');
                }
            });
    }
    //capitalize first letter of job title automatically
    $("#job\\[title\\]").keyup(function (e) {
        let title = $(this);
        let val = title.val();
        title.val(val.charAt(0).toUpperCase() + val.slice(1));

    });

    // Add a testimonial Email
    $(document).on('click', '.mfp-wrap .innerPopup-l2 #addTestimonialEmail', function (e) {
        e.preventDefault();

        if (cpt === undefined) {
            cpt = parseInt($(this).attr('data-index'));
        }

        var link = $(this).attr('data-link'),
            clonedEmail, input, label;

        cpt += 1;

        clonedEmail = $('#' + link).eq(0).clone();
        clonedEmail.removeAttr('id');

        input = clonedEmail.find('input');
        //input.attr( 'name', input.attr('name').replace('[0]', '[' + ( cpt - 1 ) + ']') );
        input.attr('id', input.attr('id').replace('-1', '-' + cpt));
        input.val('');

        label = clonedEmail.find('label');
        label.attr('for', label.attr('for').replace('-1', '-' + cpt));
        label.text(label.text().replace(' *', ''));

        clonedEmail.appendTo('#emails').hide().show(350);
    });

    /* Initialiser les lightbox ******************************************/
    // Pour les images
    $('.lightbox:not([disabled])').magnificPopup({
        type: 'image'
    });

    // Pour les galeries d'images
    $('.gallery a.galleryEl:not([disabled])').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true,
            tCounter: '%curr% ' + $.i18n.prop('magnific_of') + ' %total%' // markup of counter
        }
    });

    // Pour les contenus ajax
    $('.cLightbox:not([disabled])').magnificPopup({
        type: 'ajax',
        'closeOnBgClick': true,
        'showCloseBtn': ($(this).attr('data-showCloseBtn') === "true" ? true : false),
        'preloader': false
    });

    // Bouton fermeture custom
    $(document).on('click', '.closePopup', function () {
        $.magnificPopup.close();
    });

    //évite les rage click
    $('.oneSubmit').closest('form').not('.validForm').on('click', '.oneSubmit', function () {
        $form = $(this).parents('form');
        console.log('disabled submit');
        $(this).attr("disabled", true);
        $form.submit();
    });

    /* Script pour les comptes utilisateurs ******************************************/
    $(document).on('click', '.sendToRegister', function (e) {
        e.preventDefault();

        var el = $(this);
        $havePackage = el.attr('data-package');
        if ($havePackage == 0) {
            swal({
                    title: el.attr('data-title'),
                    text: el.attr('data-text'),
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#000000',
                    //confirmButtonText: el.attr('data-confirm-btn-text'),
                    cancelButtonText: el.attr('data-cancel-btn-text'),
                    closeOnConfirm: false,
                    closeOnCancel: true,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    customClass: 'swalApplyJob',
                    html: true
                },
                function () {
                    swal('Redirection', el.attr('data-redirect-text'), 'success');
                    window.location = el.attr('data-redirect-uri');
                });
            console.log('pop pub');
        } else {
            console.log('pop no pub');
            swal('Redirection', el.attr('data-redirect-text'), 'success');
            window.location = el.attr('data-redirect-uri');
        }

    });
    $(document).on('submit', '.applyForAJob', function (e) {
        e.preventDefault();

        var redirectUri = $(this).find('input[name="redirectUri"]').val(),
            redirectUriChange = $(this).find('input[name="redirectUriChange"]').val();

            swal({
                    title: $.i18n.prop('swal_send_my_cv'),
                    text: $.i18n.prop('swal_button_cancel'),
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#000000',
                    confirmButtonText: $.i18n.prop('swal_send_my_cv_now'),
                    cancelButtonText: $.i18n.prop('swal_more_xp'),
                    closeOnConfirm: false,
                    closeOnCancel: false,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    customClass: 'swalApplyJob',
                    html: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        swal($.i18n.prop('swal_redirection'), $.i18n.prop('swal_sending_your_cv'), 'success');
                        window.location = redirectUri;
                    } else {
                        swal($.i18n.prop('swal_redirection'), $.i18n.prop('swal_adding_xp'), 'success');
                        window.location = redirectUriChange;
                    }
                });

        return false;
    });

$(document).on('submit','.applyForAJobFromLink',function(event){
            event.preventDefault();
            var actualUrl = $(this).attr('action');
            var rediectToUrl = $(this).find('input[name="redirectUri"]').val();
            var data = $(this).serialize();
            $.ajax({
                type: 'POST',
                url: actualUrl,
                data: data,
                beforeSend: function() {
                    
                },
                success: function(data) {
                    window.open(rediectToUrl, "_blank");
                },
                error: function(xhr) { // if error occured
                   // console.log("Error occured.please try again");
                   // console.log(xhr.statusText + xhr.responseText);
                },
                complete: function() {
                    
                },
                dataType: 'json'
            });
        });
        
    //Bouton de fermeture ("annuler" total) custom swal confirm.
    $(document).on('click', '.closeSwal', function (event) {
        event.preventDefault();
        swal.close();
    });

    // Click sur l'envoi d'une offre
    $('.sendToRetirees').click(function (e) {
        e.preventDefault();

        var el = $(this);

        swal({
                title: el.attr('data-title'),
                text: el.attr('data-text'),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: '#000000',
                confirmButtonText: el.attr('data-confirm-btn-text'),
                cancelButtonText: el.attr('data-cancel-btn-text'),
                closeOnConfirm: false,
                closeOnCancel: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                customClass: 'swalApplyJob',
                html: true
            },
            function () {
                swal('Redirection', el.attr('data-redirect-text'), 'success');
                window.location = el.attr('data-redirect-uri');
            });
    });

    $('.confirm').click(function (e) {
        e.preventDefault();
        var el = $(this);

        swal({
                title: el.attr('data-title') ? el.attr('data-title') : $.i18n.prop('swal_confirm'),
                text: el.attr('data-text') ? el.attr('data-text') : $.i18n.prop('swal_really_want_do_this'),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: '#000000',
                confirmButtonText: el.attr('data-confirm-btn-text') ? el.attr('data-confirm-btn-text') : $.i18n.prop('swal_confirm_verb'),
                cancelButtonText: el.attr('data-cancel-btn-text') ? el.attr('data-cancel-btn-text') : $.i18n.prop('swal_cancel_verb'),
                closeOnConfirm: false,
                closeOnCancel: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                customClass: 'swalApplyJob',
                html: true
            },
            function () {
                swal('Redirection', (el.attr('data-redirect-text') ? el.attr('data-redirect-text') : $.i18n.prop('swal_redirection')), 'success');
                window.location = el.attr('data-redirect-uri') ? el.attr('data-redirect-uri') : el.attr('href');
            });
    });

    //Sidebar (barre latérale) compte utilisateur : Ouvrir/fermer sur mobile
    $('#btnOpenSidebar').click(function () {
        toggleSidebar($('#sidebar'));
    });

    //Le bouton Fermer ferme toujours, lui
    $('#btnCloseSidebar').click(function () {
        toggleSidebar($('#sidebar'));
    });

    //Programmation de l'accordéon : Ouverture/fermeture sur le bouton et le header entier
    $(document).on('click', '.btnRollUnroll, .accordion > header', function (event) {
        var accordion = $(this).closest('.accordion');

        //Éviter boucle infinie avec la fonction qui fait que le header entier ouvre l'accordéon
        event.stopPropagation();

        if (accordion.hasClass('open')) {
            closeAccordion(accordion);
        } else {
            openAccordion(accordion);
        }
    });

    // Ajouter une confirmation avant les action delete
    $(document).on('click', '.confirmDelete', function (e) {
        e.preventDefault();
        el = $(this);
        swal({
            title: el.attr('data-title'),
            text: el.attr('data-text'),
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#000000',
            confirmButtonText: el.attr('data-confirm-btn-text'),
            cancelButtonText: el.attr('data-cancel-btn-text'),
            closeOnConfirm: false,
            closeOnCancel: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            html: true
        }, function () {
            swal('Redirection', el.attr('data-redirect-text'), 'success');
            window.location = el.attr('data-redirect-uri');
        });


    });

    //Cliquer n'importe où dans le compte (sauf dans la sidebar même, grâce à stopPropagation) ferme la sidebar.
    $('body.account').click(function () {
        toggleSidebar($('#sidebar'), 'close');
    });

    //Ne pas fermer la sidebar en cliquant dans elle-même.
    $('body.account #sidebar').click(function (event) {
        event.stopPropagation();
    });

    //Liens disabled et vides ne font rien
    $(document).on('click', 'a[disabled], a[href="#"]', function (event) {
        event.preventDefault();
    });

    $('.smallipop').smallipop({
        'preferredPosition': 'bottom',
        'theme': 'white emploiretraite'
    });

    $('.extraInfoWorktype').smallipop({
        'preferredPosition': 'bottom',
        'theme': 'white emploiretraite'
    });

    //Gestion des tooltips des icônes de statut des offres inactives
    $('.history #main .offer .state .icon').smallipop({
        'preferredPosition': 'bottom',
        'theme': 'white emploiretraite'
    });

    //Gestion des tooltips des indicateurs de chiffres (nombre de retraités correspondant aux critères et nombre de CV)
    $('#main .offer .indicator').smallipop({
        'preferredPosition': 'bottom',
        'theme': 'white emploiretraite'
    });

    //Gestion des popups d'infos supplémentaires sur la page de sélection du forfait (dans les bulles du haut)
    $('.extraInfoBubble').click(function () {

        var popupContent = $(this).children('.mfp-hide').html();

        $.magnificPopup.open({
            items: {
                type: 'inline',
                src: '<div class="innerPopup extraBubble"> <button class="closePopup btnCloseRound larger"> <span class="icon">' + $.i18n.prop('magnific_close') + '</span> </button> <div class="innerPopup-l2"> ' + popupContent + ' </div> </div>'
            }
        });
    });

    //Gestion des popups d'infos supplémentaires sur la page de sélection du type d'employeur dans l'inscription retraité
    $('.extraInfoWorktype').click(function () {

        var popupContent = $(this).children('.mfp-hide').html();

        $.magnificPopup.open({
            items: {
                type: 'inline',
                src: '<div class="innerPopup"> <button class="closePopup btnCloseRound larger"> <span class="icon">' + $.i18n.prop('magnific_close') + '</span> </button> <div class="innerPopup-l2"> ' + popupContent + ' </div> </div>'
            }
        });
    });

    //Gestion des popups d'infos supplémentaires sur la page de sélection du forfait (/pricing/companies)
    $('.planTable th.highlighted .extraInfo').click(function () {

        var popupContent = $(this).children('.mfp-hide').html();

        $.magnificPopup.open({
            items: {
                type: 'inline',
                src: '<div class="innerPopup"> <button class="closePopup btnCloseRound larger"> <span class="icon">' + $.i18n.prop('magnific_close') + '</span> </button> <div class="innerPopup-l2"> ' + popupContent + ' </div> </div>'
            }
        });
    });
    $('.pricePlans .monthlyPlans .extraInfo .trigger').click(function () {

        var popupContent = $(this).siblings('.mfp-hide').html();

        $.magnificPopup.open({
            items: {
                type: 'inline',
                src: '<div class="innerPopup"> <button class="closePopup btnCloseRound larger"> <span class="icon">' + $.i18n.prop('magnific_close') + '</span> </button> <div class="innerPopup-l2"> ' + popupContent + ' </div> </div>'
            }
        });
    });

    //Gestion du tooltip "8 commentaires" dans un article de blogue (le contenu est simplement dans le title)
    $('.singleHeader .comments a').smallipop(
        {
            'preferredPosition': 'bottom',
            'theme': 'white emploiretraite',
            'triggerOnClick': false
        }
    );

    //Gestion du tooltip "En réponse à" sur la petite flèche d'un commentaire qui est une réponse
    $('.commentList .comment .isReplyTo a').smallipop({
        'preferredPosition': 'bottom',
        'theme': 'white emploiretraite',
        'triggerOnClick': false
    });

    //Gestion du tooltip de partage d'article de blogue
    /*
        $('.singleHeader .share button').smallipop(
            {
                'preferredPosition': 'bottom',
                'theme': 'white emploiretraite',
                'triggerOnClick': true,
                'hideDelay': 9999999,
                'hideOnPopupClick': false
            }
        );
    */

    //Lien Partager / Permalien des commentaires : Copie le lien vers le commentaire dans le presse-papiers / clipboard
    $('.comment a.share').click(function (event) {
        event.preventDefault();
        var theLink = $(this).attr('data-share-url');
        var theHash = $(this).attr('href');
        theHash = theHash.replace('#', '');

        if (copyTextToClipboard(theLink)) {
            swal($.i18n.prop('clip_link_copied'), $.i18n.prop('clip_link_to_comment_copied'), "success");
        } else {
            swal($.i18n.prop('clip_error'), $.i18n.prop('clip_link_to_comment_not_copied'), "error");
            window.location.hash = theHash;
        }
    });

    //Lien Répondre des commentaires : Fait apparaître le formulaire de réponse sous le commentaire, y indique à qui on répond et scroll vers lui.
    $('.comment a.reply').click(function (event) {
        event.preventDefault();
        var theComment = $(this).parents('.theComment');

        if (!theComment.hasClass('hasReply')) {
            theComment.addClass('hasReply');

            var theForm = $('.tmpReplyContainer').html();
            var replyContainer = $(this).parents('.commentInner').siblings('.replyContainer');
            replyContainer.html(theForm);

            var replyInner = replyContainer.children('.replyToComment');
            var replyToLink = replyContainer.find('.replyTo a');
            var replyToUsername = replyToLink.find('.username');
            var inputReplyId = replyContainer.find('.replyToId');
            var theHash = $(this).attr('href');
            theHash = theHash.replace('#', '');

            var strReplyId = $(this).attr('data-reply-to');
            var strReplyName = $(this).attr('data-reply-to-name');

            inputReplyId.val(strReplyId);
            replyToLink.attr('href', '#comment-' + strReplyId);
            replyToUsername.text(strReplyName);
            replyInner.attr('id', 'reply-to-comment-' + strReplyId);

            replyContainer.slideDown();
            replyContainer.addClass('appearing');

            //window.location.hash = theHash;
        }
    });

    //Bouton annuler du formulaire de réponse
    $(document).on('click', '.replyToComment .buttons .cancel', function () {
        $(this).parents('.replyContainer').removeClass('appearing').slideUp();
        $(this).parents('.replyContainer').siblings('.commentInner').children('.theComment').removeClass('hasReply');
    });


    //Le "?" contenant une longue description de l'ensemble de forfaits donne toujours un popup.
    $('.packages table thead .extraInfo .trigger').click(function () {
        var popupContent = $(this).siblings('.tooltip').html();
        var popupTitle = $(this).attr('data-popup-title');

        $.magnificPopup.open({
            items: {
                type: 'inline',
                src: '<div class="innerPopup"> <button class="closePopup btnCloseRound larger"> <span class="icon">' + $.i18n.prop('magnific_close') + '</span> </button> <div class="innerPopup-l2"> <h1>' + popupTitle + '</h1> ' + popupContent + ' </div> </div>'
            }
        });
    });

    // Mapper le datepicker
    var $datepickers = $('.airDatepicker');

    // note: the datepicker script is loaded only on the pages which have datepickers, so it is not safe to just call datepicker() unconditionally
    if ($datepickers.length > 0) {
        $datepickers.css('cursor', 'pointer');
        $datepickers.datepicker();
    }

    // Gérer l'achat
    $(document).on('change click touch', '#employerCVS input.candidate', function () {
        var incrementNumberDiv = $('.contactCandidates .nbSelected'),
            nbChecked = $('#employerCVS .candidate:checkbox:checked').length;

        incrementNumberDiv.text(nbChecked);
        if (nbChecked > 0) {
            $('#revealCandidate').prop('disabled', false);

            if (nbChecked > 3) {
                console.log('forfait plus que 3 users');
                $('#revealAllCandidates').prop('disabled', false);
                swal({
                        title: $.i18n.prop('swal_careful'),
                        text: $.i18n.prop('swal_250'),
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: '#000000',
                        confirmButtonText: $.i18n.prop('swal_view_packages'),
                        cancelButtonText: "Fermer",
                        closeOnConfirm: false,
                        closeOnCancel: true,
                        //TODO : Faire fonctionner l'escape key et outside click et les différencier du bouton confirm de base.
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        customClass: 'swalApplyJob',
                        html: true
                    },
                    function () {
                        console.log('buy it');
                        $('#transactionType').val('allRetired');
                        $('#employerCVS').find('input:checkbox').prop('checked', true);
                        swal($.i18n.prop('swal_redirection'), $.i18n.prop('swal_redirect_to_packages'), 'success');
                        $("form:first").submit();
                        //window.location = $('#revealCandidate').attr('data-packageurl');
                    });
            } else {
                $('#revealAllCandidates').prop('disabled', true);
            }
        } else {
            $('#revealCandidate').prop('disabled', true);
        }
    });


    /*
        Ceci prend tout les retraité et donne un prix de groupe.
     */
    $(document).on("click", "#revealAllCandidates", function () {
        //change la valeur du transaction type
        $('#transactionType').val('allRetired');
        $('#employerCVS').find('input:checkbox').prop('checked', true);
        $("form:first").submit();
    });


    //clique dessus pour activer un atout
    $(document).on("click", ".atoutenable", function () {
        var id = $(this).attr('id');
        var val = $(this).prop('checked');
        var atout = $("#atout" + id);
        var label = $("#labelatout" + id);
        atout.prop('disabled', !val);
        if (!val) {
            label.css('opacity', '0.5');
            atout.prop('checked', val);
        } else {
            label.css('opacity', '');
        }
    });

    //Cliquer un lien d'ancre (menant dans la même page) dans une popup ferme la popup
    $(document).on('click', '.mfp-wrap .innerPopup-l2 a', function () {
        var href = $(this).attr('href');

        //Si le href contient un # et a au moins 2 caractères (ce n'est donc pas un <a href="#">), il s'agit d'une ancre dans la même page valide.
        if (href.indexOf('#') !== -1 && href.length >= 2) {
            $.magnificPopup.close();
        }
    });

    //Fermeture d'une alerte
    $(document).on('click', '.dismissAlert', function () {
        var theAlert = $(this).parents('.alert');
        var theButton = $(this);

        theButton.addClass('vanishing');

        //On attend que le bouton ait fini de disparaître, pour pas que le bouton se fasse couper dans le wrap du slideDown
        setTimeout(function () {
            theAlert.addClass('vanishing');
            theAlert.slideUp(200);
        }, 50);
    });

    //Au chargement, on met le bon chiffre dans les compteurs de caractères
    $('.hasCharCounter').each(function () {
        doCharCounter($(this));
    });

    //Compteur de caractères
    $(document).on('keyup paste cut change click focus blur mousedown mouseup touchstart touchend contextmenu dragstart dragend input', '.hasCharCounter', function () {
        doCharCounter($(this));
    });

    //Callback Owl Carousel
    var owlCarousel = $('.slider .sliderElements');
    owlCarousel.on('initialized.owl.carousel', function () {
        //Labels pour personnes avec déficience visuelle sur l'interface de navigation d'Owl Carousel
        $('.slider .owl-controls .owl-prev')
            .attr('aria-label', $.i18n.prop('owl_prev'))
            .attr('tabindex', '0')
            .keydown(function (e) {
                if (e.key === 'Enter' || e.key === ' ') {
                    $(this).click();
                }
            });
        $('.slider .owl-controls .owl-next')
            .attr('aria-label', $.i18n.prop('owl_next'))
            .attr('tabindex', '0')
            .keydown(function (e) {
                if (e.key === 'Enter' || e.key === ' ') {
                    $(this).click();
                }
            });

        // Find the currently active owl-item and add tabindex=0
        var activeItems = $('.owl-item.active, .owl-item.cloned.active');
        activeItems.attr('tabindex', '0');
        activeItems.attr('aria-hidden', 'false');

        $('.owl-item:not(.active)').attr('aria-hidden', 'true');
    });

       //Slider / carrousel de logos de l'accueil
    //OwlCarousel 2
    owlCarousel.owlCarousel({
        items: 5,
        loop: true,
        nav: true,
        navRewind: true,
        navText: ['<span>' + $.i18n.prop('owl_prev_arrow') + '</span>', '<span>' + $.i18n.prop('owl_next_arrow') + '</span>'],
        dots: false,
        lazyLoad: true,
        autoplay: false,
        autoplayHoverPause: true,
        margin: 40,
        responsiveBaseElement: $('.slider .sliderElements').get(0),
        responsive: {
            0: {items: 1},
            480: {items: 2},
            600: {items: 3},
            1000: {items: 4},
            1100: {items: 5}
        }
    });

    owlCarousel.on('changed.owl.carousel', function () {
        setTimeout(function () {
            var owlItem = $('.owl-item');
            removeAttribute('tabindex', owlItem);

            var activeItems = $('.owl-item.active, .owl-item.cloned.active');
            addAttribute('tabindex', '0', activeItems);
            addAttribute('aria-hidden', 'false', activeItems);

            hideInactiveOwlItems(owlItem);
        }, 50);
    });

    function removeAttribute(attribute, element) {
        element.removeAttr(attribute);
    }

    function addAttribute(attributeName, value, element) {
        element.addAttribute(attributeName, value);
    }

    function hideInactiveOwlItems(owlItem) {
        owlItem.not('.active').attr('aria-hidden', 'true');
    }

    //Masonry dans l'accueil / dashboard du compte pour coller mes éléments verticalement peu importe leur hauteur
    if (typeof Masonry === 'function') {
        //Si Masonry existe
        $('.dashboardModules .modulesInner').masonry({
            itemSelector: '.module',
            columnWidth: '.dashboardModules .module:not(.fullWidth)',
            gutter: '.gutterSizer',
            percentPosition: true,
            debug: true,
        });
    }

    //On donne la couleur à la ligne de tableau au chargement aussi
    $('.durationSelect table input[type="radio"]:checked').parents('tr').addClass('checked');

    //Changement de couleur des lignes de tableau de sélection de durée de forfait quand on coche la case
    $('.durationSelect table input[type="radio"]').change(function () {
        var parentTr = $(this).parents('tr');

        parentTr.siblings().removeClass('checked');

        if (!parentTr.hasClass('checked')) {
            parentTr.addClass('checked');
        }
    });

    //Cliquer sur une ligne de tableau coche la case qu'elle contient
    $('.durationSelect table tbody tr').click(function () {
        $(this).find('input[type="radio"]').prop('checked', true).change();
    });

    // Refaire le labeling du bouton stripe
    $('#stripeBtn').on('click', function (event) {
        event.preventDefault();
        var $button = $(this),
            $form = $button.parents('form');
        var opts = $.extend({}, $button.data(), {
            token: function (result) {
                $form.append($('<input>').attr({type: 'hidden', name: 'stripeToken', value: result.id})).submit();
            }
        });
        StripeCheckout.open(opts);
    });

    //Lazyload automatique des images de contenu
    $("img.lazy").show().lazyload({
        effect: "fadeIn",
        threshold: 300,
        skip_invisible: true
    });


    // Code pour le manager
    if ($('.refuseOffer').length > 0) {
        $('a.refuseOffer').click(function (e) {
            e.preventDefault();

            var formID = $(this).attr('data-form-related'),
                container = $('#' + formID),
                form = container.find('form'),
                msg = container.find('.minimsg'),
                textarea = form.find('textarea').val('');

            container.show(150);

            // Action sur le cancel
            form.find('a.cancel').click(function () {
                form.find('textarea').val('');
                container.hide(150);
            });

            // Action sur le send
            form.find('.btnSend').click(function () {
                if (textarea.val() != '') {
                    form.submit();
                } else {
                    msg.show(150).delay(2000).hide(150);
                }
            });
        });
    }

    // Code pour le manager
    if ($('.addNote').length > 0) {
        $('a.addNote').click(function (e) {
            e.preventDefault();

            var formID = $(this).attr('data-form-related'),
                container = $('#' + formID),
                form = container.find('form'),
                textarea = form.find('textarea');

            container.show(150);

            // Action sur le cancel
            form.find('a.cancel').click(function () {
                form.find('textarea').val('');
                container.hide(150);
            });
        });
    }

    if ($('#avatarUpload').length > 0) {
        cropperAvatar = new Croppic('avatarUpload', {
            customUploadButtonId: 'customUpload',
            uploadUrl: $('#avatarUpload').attr('data-upload'),
            cropUrl: $('#avatarUpload').attr('data-crop'),
            //loadPicture: $('#avatarUpload').attr('data-default'),
            imgEyecandy: true,
            imgEyecandyOpacity: 0.2,
            outputUrlId: "avatarLocation",
            //cropData:{ "idProject":$('#idProject').val() },
            modal: true,
            //loadPicture: rootURL +  'images/spinner.gif',
            rotateControls: true,
            doubleZoomControls: false,
            // Si le user annule on va domper toute ces images
            //onReset: function(){ console.log('onReset') }
            onAfterImgUpload: function () {
                $('#croppicModal .cropControlZoomIn').append('<span class="cropTips">' + $.i18n.prop('croppic_enlarge') + '</span>');
                $('#croppicModal .cropControlZoomOut').append('<span class="cropTips">' + $.i18n.prop('croppic_shrink') + '</span>');
                $('#croppicModal .cropControlRotateLeft').append('<span class="cropTips">' + $.i18n.prop('croppic_rotate_right') + '</span>');
                $('#croppicModal .cropControlRotateRight').append('<span class="cropTips">' + $.i18n.prop('croppic_rotate_left') + '</span>');
                $('#croppicModal .cropControlCrop').append('<span class="cropTips">' + $.i18n.prop('croppic_confirm') + '</span>');
                $('#croppicModal .cropControlReset').append('<span class="cropTips">' + $.i18n.prop('croppic_cancel') + '</span>');
            },
            onAfterImgCrop: function () {
                $('.needUpload').addClass('uploadFinished');
            }
        });
    }
    // Traduction / langues des chaînes dans le JS : Voir i18n_init.js et public/js/i18n/Messages_XX.properties

});

